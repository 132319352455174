<template>
  <div class="mx-a mb-16">
    <div class="current-bar pyx mb-16">
      <router-link
        v-for="nav in routerList"
        :key="nav.id"
        :to="nav.path"
        :class="`depth2 fm-16 ${G_NAV_ID.startsWith(nav.id) ? 'selected' : ''}`"
      >
        {{ nav.title }}
      </router-link>
    </div>
    <!-- 기본정보 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap">
        <span class="header-category">프로필 기본정보</span>
      </div>
      <!-- 프로필 사진 -->
      <div class="info-content-wrap" style="align-items: flex-start">
        <span class="content-category">프로필 사진</span>
        <div class="d-flex" style="align-items: flex-start">
          <!-- <img
            class="profile-info-image"
            src="@/assets/image/profile_info.png"
            v-if="!profileImageSrc && isEdit"
          /> -->
          <img
            class="profile-info-image mr-8"
            :src="profileImageSrc"
            v-if="profileImageSrc"
          />
          <button-common
            :size="SIZE_T"
            :clr="CLR_W"
            @click="$refs.fileProfileRef.click()"
            v-if="isEdit"
            >사진선택</button-common
          >
          <input
            accept="image/*"
            type="file"
            class="d-none"
            ref="fileProfileRef"
            @input="handleProfileImageUpload"
          />
        </div>
      </div>
      <!-- 프로필 배경 사진 -->
      <div class="info-content-wrap" style="align-items: flex-start">
        <span class="content-category">프로필 배경 사진</span>
        <img
          class="mr-8"
          style="height: 120px"
          :src="profileBackImageSrc"
          v-if="profileBackImageSrc && !isEdit"
        />
        <div class="d-flex" style="align-items: flex-start" v-if="isEdit">
          <img
            class="mr-8"
            style="height: 120px"
            :src="profileBackImageSrc"
            v-if="profileBackImageSrc"
          />
          <button-common
            :size="SIZE_T"
            :clr="CLR_W"
            @click="$refs.fileProfileBackgroundRef.click()"
            v-if="isEdit"
            >사진선택</button-common
          >
          <input
            accept="image/*"
            type="file"
            class="d-none"
            ref="fileProfileBackgroundRef"
            @input="handleProfileBackgroundImageUpload"
          />
        </div>
      </div>
      <!-- 닉네임 -->
      <div class="info-content-wrap">
        <span class="content-category">닉네임</span>
        <div class="flex-align-center" v-if="isEdit">
          <input-text
            class="mr-16"
            v-model="profile.nick"
            @input="
              isCheckNick = false;
              isFirst = true;
            "
          />
          <button-common :size="SIZE_T" :clr="CLR_M" @click="onCheckNick"
            >중복확인</button-common
          >
          <span
            class="nick-check"
            v-if="
              isCheckNick ||
              (checkNick === profile.nick && checkNick.length !== 0)
            "
            >닉네임 확인 완료</span
          >
          <span class="nick-error" v-else-if="!isCheckNick && !isFirst"
            >이미 등록된 닉네임 입니다. 다른 닉네임으로 설정해주세요.</span
          >
          <span class="nick-error" v-else>닉네임 중복확인이 필요합니다.</span>
        </div>
        <span v-else>{{ profile.nick }}</span>
      </div>
      <!-- 호칭 선택 -->
      <div class="info-content-wrap">
        <span class="content-category">호칭</span>
        <div class="flex-align-center" v-if="isEdit">
          <select-box
            :opt-nm="SELT_NICK"
            :size="SIZE_M"
            v-model="profile.designation"
          />
        </div>
        <span v-else>{{ profile.designation }}</span>
      </div>
      <!-- 활동지역 -->
      <div class="info-content-wrap">
        <span class="content-category">활동지역</span>
        <div class="flex-align-center" v-if="isEdit">
          <input-text
            disabled
            class="mr-16"
            style="width: 350px"
            v-model="profile.busiArea"
          />
          <button-common :size="SIZE_T" :clr="CLR_M" @click="openAddress"
            >주소검색</button-common
          >
        </div>
        <span v-else>{{ profile.busiArea }}</span>
      </div>
    </div>

    <!-- 마케팅 계정 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap">
        <span class="header-category">마케팅 계정</span>
      </div>
      <!-- 계정 등록 -->
      <div class="info-content-wrap" v-if="isEdit">
        <span class="content-category">계정 등록</span>
        <div class="flex-align-center" v-if="isEdit">
          <select-box
            class="mr-16"
            :opt-nm="SELT_MARKETING"
            :size="SIZE_M"
            v-model="marketing"
          />
          <button-common :size="SIZE_T" :clr="CLR_M" @click="addMarketing"
            >계정추가</button-common
          >
        </div>
      </div>
      <!-- 추가된 계정들 -->
      <div
        class="info-content-wrap"
        v-for="data in marketingList"
        :key="data.id"
      >
        <span class="content-category">{{ getMarketingName(data.title) }}</span>
        <div class="flex-align-center" v-if="isEdit">
          <input-text class="mr-16" v-model="data.value" />
          <img
            class="icon-marketing-delete"
            src="@/assets/image/marketing_delete.png"
            @click="deleteMarketing(data.id)"
          />
        </div>
        <span v-else>{{ data.value }}</span>
      </div>
    </div>

    <!-- 카테고리 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap">
        <span class="header-category">#카테고리</span>
      </div>
      <!-- 카테고리 -->
      <div class="info-content-wrap">
        <span class="content-category">카테고리</span>
        <div class="flex-align-center" v-if="isEdit">
          <select-box
            class="mr-16"
            :opt-nm="categoryA"
            :size="SIZE_M"
            v-model="profile.cateEsntlIdA"
          />
          <select-box
            class="mr-16"
            :opt-nm="categoryB"
            :size="SIZE_M"
            v-model="profile.cateEsntlIdB"
          />
          <select-box
            class="mr-16"
            :opt-nm="categoryC"
            :size="SIZE_M"
            v-model="profile.cateEsntlIdC"
          />
          <select-box
            :opt-nm="categoryD"
            :size="SIZE_M"
            v-model="profile.cateEsntlIdD"
          />
        </div>
        <span v-if="!isEdit && profile.cateEsntlIdA"
          >{{ getCategoryName(categoryA, profile.cateEsntlIdA) }} /
          {{ getCategoryName(categoryB, profile.cateEsntlIdB) }} /
          {{ getCategoryName(categoryC, profile.cateEsntlIdC) }} /
          {{ getCategoryName(categoryD, profile.cateEsntlIdD) }}</span
        >
      </div>
    </div>

    <!-- 프로필 간략 소개 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap">
        <span class="header-category">프로필 간략 소개</span>
      </div>
      <!-- 프로필 간략 소개 -->
      <div class="info-content-wrap" style="padding: 16px 32px">
        <editor-common
          class="w-100"
          :content.sync="profile.profileSimple"
          v-if="isEdit"
        />
        <span v-html="profile.profileSimple" v-else />
      </div>
    </div>

    <!-- 프로필 상세 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap">
        <span class="header-category">프로필 상세</span>
      </div>
      <!-- 프로필 상세 -->
      <div class="info-content-wrap" style="padding: 16px 32px">
        <editor-common
          class="w-100"
          :content.sync="profile.profileDetail"
          v-if="isEdit"
        />
        <span v-html="profile.profileDetail" v-else />
      </div>
    </div>

    <!-- 아래 버튼 -->
    <div class="fixed d-flex align-items-center flex-end under-finish">
      <!-- <div class="warning fr-12 mr-32">
        <slot />
      </div> -->
      <div>
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          @click="onCancel"
          v-if="isEdit"
          >취소</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          :disabled="
            !(
              isCheckNick ||
              (checkNick === profile.nick && checkNick.length !== 0)
            )
          "
          v-if="isEdit"
          @click="editProfileData"
          >확인</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          @click="onEdit"
          v-if="!isEdit"
          >수정</button-common
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CompanyProfile",
  mixins: [],
  components: {},

  /*** data ***/
  data() {
    return {
      profile: {
        profileImage: null,
        profileBackImage: null,
        nick: "",
        designation: "",
        busiArea: "",
        cateEsntlIdA: "",
        cateEsntlIdB: "",
        cateEsntlIdC: "",
        cateEsntlIdD: "",
        profileSimple: "",
        profileDetail: "",
        maketingList: "",
      },
      profileImageSrc: "",
      profileBackImageSrc: "",
      isEdit: false,
      marketing: "",
      marketingList: [],
      categoryA: [],
      categoryB: [],
      categoryC: [],
      categoryD: [],
      routerList: [],
      checkNick: "",
      isCheckNick: false,
      isFirst: true,
    };
  },

  /*** created ***/
  async created() {
    await this.$store.dispatch("A_NAV_SHOW", false);
  },

  /*** mounted ***/
  async mounted() {
    this.routerList.push({
      id: "11-2-1-1",
      path: `/company/partener/info/${this.$route.params.id}`,
      title: "파트너정보",
    });
    console.log(this.routerList);
    this.routerList.push({
      id: "11-2-1-2",
      path: `/company/profile/${this.$route.params.id}`,
      title: "프로필",
    });
    await this.getCategoryA();
    await this.getCategoryB();
    await this.getCategoryC();
    await this.getCategoryD();
    await this.getProfileData();
  },

//   beforeRouteLeave(to, from, next) {
//     this.toRoute.path = to.fullPath;
//     this.toRoute.meta = to.meta.id;
//     if (this.isReset) {
//       next();
//     } else {
//       next(false);
//       if (!this.isReset) {
//         this.onPageReset();
//       }
//     }
//   },

  /*** computed ***/
  computed: {
    ...mapGetters(["G_NAV_ID"]),
  },

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    async getCategoryA() {
      const rs = await this.$axios({
        url: "/categoryA",
        params: {},
      });
      if (rs.result === "SUC") {
        this.categoryA = rs.resultList.map((item) => {
          return { title: item.title, value: item.esntlId };
        });
        this.categoryA.unshift({ title: "선택안함", value: "" });
      } else {
        console.log("카테고리A 조회 실패");
      }
    },
    async getCategoryB() {
      const rs = await this.$axios({
        url: "/categoryB",
        params: {},
      });
      if (rs.result === "SUC") {
        this.categoryB = rs.resultList.map((item) => {
          return { title: item.title, value: item.esntlId };
        });
        this.categoryB.unshift({ title: "선택안함", value: "" });
      } else {
        console.log("카테고리B 조회 실패");
      }
    },
    async getCategoryC() {
      const rs = await this.$axios({
        url: "/categoryC",
        params: {},
      });
      if (rs.result === "SUC") {
        this.categoryC = rs.resultList.map((item) => {
          return { title: item.title, value: item.esntlId };
        });
        this.categoryC.unshift({ title: "선택안함", value: "" });
      } else {
        console.log("카테고리C 조회 실패");
      }
    },
    async getCategoryD() {
      const rs = await this.$axios({
        url: "/categoryD",
        params: {},
      });
      if (rs.result === "SUC") {
        this.categoryD = rs.resultList.map((item) => {
          return { title: item.title, value: item.esntlId };
        });
        this.categoryD.unshift({ title: "선택안함", value: "" });
      } else {
        console.log("카테고리D 조회 실패");
      }
    },
    async getProfileData() {
      const rs = await this.$axios({
        url: "/admin/partner/reg/profile",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.marketingList = [];
        this.profile = rs.resultData.profile;
        this.profile = rs.resultData.profile;
        if (rs.resultData.profile.profileImage) {
          this.profileImageSrc =
            "https://medal.sailors.kr/upload/partner/" +
            rs.resultData.profile.profileImage;
        }
        if (rs.resultData.profile.profileBackImage) {
          this.profileBackImageSrc =
            "https://medal.sailors.kr/upload/partner/" +
            rs.resultData.profile.profileBackImage;
        }
        if (rs.resultData.maketingList) {
          rs.resultData.maketingList.forEach((item) => {
            this.marketingList.push({
              id: this.$nnid(),
              title: item.type,
              value: item.nick,
            });
          });
        }
      } else {
        console.log("프로필 조회 실패");
      }
    },
    async editProfileData() {
      const formData = new FormData();
      formData.append("esntlId", this.$route.params.id);
      formData.append("profileImage", this.profile.profileImage);
      formData.append("profileBackImage", this.profile.profileBackImage);
      formData.append("nick", this.profile.nick);
      formData.append("designation", this.profile.designation);
      formData.append("busiArea", this.profile.busiArea);
      formData.append("cateEsntlIdA", this.profile.cateEsntlIdA ? this.profile.cateEsntlIdA : "");
      formData.append("cateEsntlIdB", this.profile.cateEsntlIdB ? this.profile.cateEsntlIdB : "");
      formData.append("cateEsntlIdC", this.profile.cateEsntlIdC ? this.profile.cateEsntlIdC : "");
      formData.append("cateEsntlIdD", this.profile.cateEsntlIdD ? this.profile.cateEsntlIdD : "");
      formData.append("profileSimple", this.profile.profileSimple);
      formData.append("profileDetail", this.profile.profileDetail);

      this.marketingList.forEach((item, index) => {
        formData.append(`maketingList[${index}][nick]`, item.value);
        formData.append(`maketingList[${index}][type]`, item.title);
      });

      const rs = await this.$axios({
        url: "/admin/partner/reg/profile/edit",
        type: "MULTI",
        params: formData,
      });
      if (rs.result === "SUC") {
        this.isEdit = false;
        await this.getProfileData();
      } else {
        console.log("프로필 수정 실패");
      }
    },
    addMarketing() {
      this.marketingList.push({
        id: this.$nnid(),
        title: this.marketing,
        value: "",
      });
    },
    deleteMarketing(id) {
      this.marketingList = this.marketingList.filter((item) => item.id !== id);
    },
    getMarketingName(type) {
      switch (type) {
        case "IS":
          return "인스타그램";
        case "FB":
          return "페이스북";
        case "YT":
          return "유튜브";
      }
    },
    getCategoryName(list, category) {
      const filter = list.filter((item) => item.value === category);
      return filter.length > 0 ? filter[0].title : "";
    },

    handleProfileImageUpload(e) {
      if (e.target.files[0]) {
        this.profile.profileImage = e.target.files[0];
        this.profileImageSrc = URL.createObjectURL(e.target.files[0]);
      }
    },

    handleProfileBackgroundImageUpload(e) {
      if (e.target.files[0]) {
        this.profile.profileBackImage = e.target.files[0];
        this.profileBackImageSrc = URL.createObjectURL(e.target.files[0]);
      }
    },

    async openAddress() {
      await this.$openAddress(
        {
          cbPrmy: this.cbSelectAddress,
        },
        this
      );
    },
    cbSelectAddress(address) {
      this.profile.busiArea = address;
    },

    async onCheckNick() {
      const rs = await this.$axios({
        url: "/partner/profile/nick/check",
        params: { nick: this.profile.nick },
      });
      this.isFirst = false;
      if (rs.result === "SUC") {
        this.isCheckNick = true;
        console.log("닉네임 체크 성공");
      } else {
        this.isCheckNick = false;
        console.log("닉네임 체크 실패");
      }
    },

    onCancel() {
      this.isEdit = false;
      this.checkNick = "";
    },

    onEdit() {
      this.isEdit = true;
      this.checkNick = this.profile.nick ? this.profile.nick : "";
    },

    /** @ bottomInfo 버튼 클릭시 ***/
    async onChangeBottomInfo(val) {
      if (val === "RESET") {
        this.toRoute.path = "/content/course/task";
        this.toRoute.meta = "2-1-1";
        this.onPageReset();
      }
      if (val === "SAVE") this.onPageSave();
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  margin-top: 10px;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  width: 200px;
}
.profile-info-image {
  width: 88px;
  height: 88px;
}
.icon-marketing-delete {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.nick-error {
  font-size: 12px;
  font-weight: 400;
  color: #f1431d;
  margin-left: 16px;
}
.nick-check {
  color: #2ec8b5;
  font-size: 12px;
  font-weight: 700;
  margin-left: 16px;
}
::v-deep .ql-editor {
  height: 200px !important;
  overflow: auto;
}
</style>
